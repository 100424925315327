<template>
  <div class="d-flex flex-column px-4 autofollow">
    <h3 class="my-4">Autofollow</h3>

    <div class="row mb-3">
      <div class="col-2">
        <h4>Type</h4>
      </div>
      <div class="col-5">
        <h4 class="text-capitalize">All {{ profileType }}</h4>
      </div>
      <div class="col-5">
        <h4 class="text-capitalize">Selected {{ profileType }}</h4>
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col-2">
        <Tabs :routes="$options.routes" />
      </div>

      <div class="col-5 h-100">
        <div class="card h-100">
          <div class="position-relative h-100 p-0 card-body">
            <div
              class="position-absolute custom-scrollbar autofollow_table-container"
            >
              <ProfilesTable
                v-model="allProfilesContains"
                in-container
                :list="allProfiles"
                list-name="list"
                :is-disabled="isAllProfilesDisabled"
                :activation-gap="100"
                :is-loading="isAllProfilesLoading"
                :is-empty="!allProfiles.length"
                @ScrollToBottom="fetchAllProfiles"
              >
                <template slot="table-content" slot-scope="{ list }">
                  <tr v-for="(item, index) in list" :key="item.id">
                    <td class="border-top-0 border-bottom">{{ index + 1 }}</td>
                    <td class="border-top-0 border-bottom">
                      {{ item.name || item.login }}
                    </td>
                    <td class="border-top-0 border-bottom">
                      <Icon
                        v-if="item.approved"
                        name="verified-pink"
                        original
                      />
                    </td>
                    <td class="border-top-0 border-bottom">
                      <button
                        v-if="item.auto_follow"
                        class="btn btn-link btn-sm w-100"
                        disabled
                      >
                        Added
                      </button>
                      <button
                        v-else
                        class="btn btn-outline-primary btn-sm w-100"
                        @click="add(item)"
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </template>
              </ProfilesTable>
            </div>
          </div>
        </div>
      </div>

      <div class="col-5 h-100">
        <div class="card h-100">
          <div class="position-relative h-100 p-0 card-body">
            <div
              class="position-absolute custom-scrollbar autofollow_table-container"
            >
              <ProfilesTable
                v-model="selectedProfilesContains"
                in-container
                :list="combinedSelectedProfiles"
                list-name="list"
                :is-disabled="isSelectedProfilesDisabled"
                :activation-gap="100"
                :is-loading="isSelectedProfilesLoading"
                :is-empty="
                  isSelectedProfilesLoading || !combinedSelectedProfiles.length
                "
                @ScrollToBottom="fetchSelectedProfiles"
              >
                <template slot="table-content" slot-scope="{ list }">
                  <tr v-for="(item, index) in list" :key="item.id">
                    <td class="border-top-0 border-bottom">{{ index + 1 }}</td>
                    <td class="border-top-0 border-bottom">
                      {{ item.name || item.login }}
                    </td>
                    <td class="border-top-0 border-bottom">
                      <Icon
                        v-if="item.approved"
                        name="verified-pink"
                        original
                      />
                    </td>
                    <td class="border-top-0 border-bottom">
                      <button
                        class="btn btn-outline-danger btn-sm w-100"
                        @click="remove(item)"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                </template>
              </ProfilesTable>
            </div>
          </div>
          <div class="card-footer">
            <button
              class="btn btn-primary"
              :class="{ 'is-loading': isSaveRequestProcessed }"
              :disabled="!isSavingNecessary"
              @click="save"
            >
              Apply
            </button>
            <transition name="fade">
              <span v-if="isHintVisible" class="ml-4 autofollow_hint">
                It will take a few minutes to apply changes.
              </span>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import listMixin from '@/mixins/lists';
import Icon from '@/components/common/Icon';
import ProfilesTable from './components/ProfilesTable';
import Tabs from './components/Tabs';

const HINT_VISIBILITY_TIME = 5000; // ms

const DATA = () => ({
  allProfilesContains: '',
  allProfiles: [],
  allProfilesNextPageToken: '',
  isAllProfilesLoading: false,

  selectedProfilesContains: '',
  selectedProfiles: [],
  selectedProfilesNextPageToken: '',
  isSelectedProfilesLoading: false,

  isSaveRequestProcessed: false,

  inserted: [],
  deleted: [],
});

const ROUTES = [
  {
    label: 'Players',
    route: {
      name: 'autofollow',
      params: { type: 'players' },
    },
    subjectType: 'profile',
  },
  {
    label: 'Organizations',
    route: {
      name: 'autofollow',
      params: { type: 'organizations' },
    },
    subjectType: 'organization',
  },
];

export default {
  name: 'AutofollowView',

  components: {
    Icon,
    ProfilesTable,
    Tabs,
  },
  mixins: [...listMixin],

  data() {
    return {
      ...DATA(),
      isHintVisible: false,
    };
  },

  computed: {
    profileType() {
      return this.$route.params.type;
    },
    subjectType() {
      return ROUTES.find(({ route }) => route.params.type === this.profileType)
        .subjectType;
    },
    isSavingNecessary() {
      return !!(this.inserted.length || this.deleted.length);
    },
    combinedSelectedProfiles() {
      return [...this.inserted, ...this.selectedProfiles];
    },
    isSelectedProfilesDisabled() {
      return (
        this.isSelectedProfilesLoading ||
        this.selectedProfilesNextPageToken === null
      );
    },
    isAllProfilesDisabled() {
      return (
        this.isAllProfilesLoading || this.allProfilesNextPageToken === null
      );
    },
  },

  beforeRouteUpdate(to, from, next) {
    return this.isSavingNecessary
      ? confirm(
          'Are you sure you want to leave the page? Changes may be lost.'
        ) && next()
      : next();
  },

  watch: {
    allProfilesContains() {
      this.allProfiles = [];
      this.allProfilesNextPageToken = '';
      this.fetchAllProfiles();
    },

    selectedProfilesContains() {
      this.selectedProfiles = [];
      this.selectedProfilesNextPageToken = '';
      this.fetchSelectedProfiles();
    },

    $route() {
      this.reload();
    },
  },

  created() {
    this.fetchAll();
    this.$options.routes = ROUTES;
  },

  methods: {
    ...mapActions([
      'getProfiles',
      'getAutofollowedProfiles',
      'setAutofollowedProfiles',
      'errorNotify',
    ]),

    fetchAllProfiles() {
      this.isAllProfilesLoading = true;
      return this.getProfiles({
        params: {
          page: this.allProfilesNextPageToken,
          contains: this.allProfilesContains,
          subject_type: this.subjectType,
        },
      })
        .then(({ next_page_token, items }) => {
          this.allProfilesNextPageToken = next_page_token;
          this.allProfiles = [...this.allProfiles, ...items];
        })
        .catch(this.errorNotify)
        .finally(() => (this.isAllProfilesLoading = false));
    },

    fetchSelectedProfiles() {
      this.isSelectedProfilesLoading = true;
      return this.getAutofollowedProfiles({
        params: {
          page: this.selectedProfilesNextPageToken,
          contains: this.selectedProfilesContains,
          subject_type: this.subjectType,
        },
      })
        .then(({ next_page_token, items }) => {
          this.selectedProfilesNextPageToken = next_page_token;
          this.selectedProfiles = [...this.selectedProfiles, ...items];
        })
        .catch(this.errorNotify)
        .finally(() => (this.isSelectedProfilesLoading = false));
    },

    fetchAll() {
      this.fetchAllProfiles();
      this.fetchSelectedProfiles();
    },

    reload() {
      Object.assign(this, DATA());
      this.fetchAll();
    },

    add(item) {
      this.inserted.push(item);
      const indexInDeleted = this.deleted.findIndex(
        ({ login }) => item.login === login
      );
      if (indexInDeleted !== -1) {
        this.deleted.splice(indexInDeleted, 1);
      }
      item.auto_follow = true;
    },

    remove(item) {
      const indexInInserted = this.inserted.findIndex(
        ({ login }) => item.login === login
      );
      if (indexInInserted !== -1) {
        this.inserted.splice(indexInInserted, 1);
        item.auto_follow = false;
      } else {
        this.deleted.push(item);
        const indexInSelected = this.selectedProfiles.findIndex(
          ({ login }) => item.login === login
        );
        this.selectedProfiles.splice(indexInSelected, 1);
        this.allProfiles.find(
          ({ login }) => item.login === login
        ).auto_follow = false;
      }
    },

    save() {
      this.isHintVisible = true;
      setTimeout(() => (this.isHintVisible = false), HINT_VISIBILITY_TIME);

      const mapFn = ({ id, login }) => id || login;
      const inserted = this.inserted.map(mapFn);
      const deleted = this.deleted.map(mapFn);

      this.isSaveRequestProcessed = true;
      return this.setAutofollowedProfiles({ inserted, deleted })
        .then(this.reload)
        .catch(this.errorNotify)
        .finally(() => (this.isSaveRequestProcessed = false));
    },
  },
};
</script>

<style lang="scss">
.autofollow {
  &_table-container {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &_hint {
    font-size: 0.75rem;
    font-weight: bold;
  }
}
</style>
