<template>
  <InfiniteScroll v-bind="$attrs" v-on="$listeners">
    <table
      slot-scope="{ list }"
      class="table table-hover mx-0 px-0 autofollow_table"
    >
      <thead>
        <tr>
          <th class="border-0"><!-- Index --></th>
          <th class="border-0 pb-0" colspan="2">
            <TextInput
              v-model="containsModel"
              label="Search"
              placeholder="Username"
              :debounce="DEBOUNCE_TIME"
            />
          </th>
        </tr>
        <tr>
          <th class="border-0"><!-- Index --></th>
          <th class="border-0 section-description">Username</th>
          <th class="border-0 section-description">Verified</th>
          <th class="border-0 section-description">Action</th>
        </tr>
      </thead>
      <template v-if="isEmpty">
        <caption v-if="isLoading" class="text-center text-muted pt-5">
          Fetching profiles...
        </caption>
        <caption v-else class="text-center text-muted">
          No profiles
        </caption>
      </template>
      <tbody v-else>
        <slot name="table-content" :list="list" />
      </tbody>
    </table>
  </InfiniteScroll>
</template>

<script>
import InfiniteScroll from '@/components/common/infiniteScroll';
import TextInput from '@/components/form/HeraFormTextInput';

const DEBOUNCE_TIME = 500; // ms

export default {
  components: {
    InfiniteScroll,
    TextInput,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isEmpty: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      DEBOUNCE_TIME,
    };
  },

  computed: {
    containsModel: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>

<style lang="scss">
.autofollow_table {
  td {
    font-size: 0.75rem;
  }
}
</style>
