<template>
  <nav class="tabs">
    <ul class="list-unstyled">
      <li
        v-for="item in routes"
        :key="item.label"
        class="position-relative pl-3 py-2"
      >
        <router-link :to="item.route">{{ item.label }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.tabs {
  font-size: 0.75rem;
  font-weight: bold;

  ul {
    border-left: solid 1px $hera-strokes-color;
  }

  a {
    color: $hera-main-text-color;

    &.router-link-active {
      color: $hera-main-color;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: -0.125rem;
        top: 0;
        width: 0.25rem;
        height: 2.125rem;
        background-color: $hera-main-color;
      }
    }
  }
}
</style>
